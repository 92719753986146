<template>
<div class="topnav">
    <!-- <div class="container">
        <nav class="navbar navbar-dark navbar-expand-lg topnav-menu">

            <div class="collapse navbar-collapse" id="topnav-menu-content" :class="{ 'show': $store.state.navigation.mobile_menu }">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <a class="nav-link dropdown-toggle arrow-none" href="#" id="topnav-dashboards" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="uil-dashboard mr-1"></i>Dashboard
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
    </div> -->
</div> 
</template>

<script>
export default {
    
}
</script>