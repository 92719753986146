<template>
<div class="navbar-custom topnav-navbar">
    <div class="container">
        <!-- LOGO -->
        <router-link class="topnav-logo" to="/">	
            <span class="topnav-logo-lg">
                <img src="@/assets/images/new_logo.svg" alt="" height="36">
            </span>
            <span class="topnav-logo-sm">
                <img src="@/assets/images/new_logo_sm.svg" alt="" height="36">
            </span>
        </router-link>

        <ul class="list-unstyled topbar-right-menu float-right mb-0">
            <li class="dropdown notification-list">
                <a class="nav-link dropdown-toggle nav-user arrow-none mr-0" data-toggle="dropdown" id="topbar-userdrop"
                    href="#" role="button" aria-haspopup="true" aria-expanded="false">
                    <span class="account-user-avatar">
                        <img src="@/assets/images/users/avatar-1.jpg" alt="user-image" class="rounded-circle">
                    </span>
                    <span>
                        <span class="account-user-name">{{ $store.state.auth.user.name }}</span>
                    </span>
                </a>
                <div class="dropdown-menu dropdown-menu-right dropdown-menu-animated topbar-dropdown-menu profile-dropdown"
                    aria-labelledby="topbar-userdrop">
                    <!-- item-->
                    <router-link :to="{name: 'UserSettings'}" class="dropdown-item notify-item">
                        <i class="mdi mdi-account-edit mr-1"></i>
                        <span>Settings</span>
                    </router-link>

                    <a href="javascript:void(0);" class="dropdown-item notify-item" @click.prevent="logout()">
                        <i class="mdi mdi-logout mr-1"></i>
                        <span>Logout</span>
                    </a>
                </div>
            </li>
        </ul>

        <a class="button-menu-mobile disable-btn" @click.prevent="$store.commit('navigation/mobile_menu_toggle')">
            <div class="lines">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </a>

    </div>
</div>  
</template>

<script>
export default {
    methods: {
		async logout() {
            await this.$store.dispatch('auth/logout')
		}
    }
}
</script>