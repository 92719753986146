<template>
	<div>
		<top-menu />
        <top-nav />

		 <!-- Start Content-->
        <div class="container mm-active">
        	<!-- Begin page -->
            <div class="wrapper mm-show">

                <div class="content-page">
                    <div class="content">
                    	<transition name="fade" mode="out-in"><router-view></router-view></transition>
                    </div>
                </div>

            </div>
        </div>
	</div>
</template>

<script>
import TopMenu from '@/components/menu/TopMenu.vue'
import TopNav from '@/components/menu/TopNav.vue'

export default {
	name: 'MainLayout',
	data: () => {
		return {

		}
	},
    mounted() {

    },
	methods: {
		async logout() {
            await this.$store.dispatch('logout')
			this.$router.push({name: 'Login'})
		},
        activeRouteName(match) {
            return this.$router.currentRoute.name.indexOf(match) !== -1
        }
    },
    components: {
        TopMenu,
        TopNav
    }
};
</script>

<style>
body[data-layout="detached"] .leftbar-user .leftbar-user-name {
    margin-left: 0px;
}

.button-menu-mobile span {
    background-color: rgba(0, 0, 0, 0.8) !important;
}
</style>
